const SmallLogo = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 34C7.62857 34 0 26.373 0 16.9964C0 7.61983 7.62857 0 17 0C26.3786 0 34 7.62697 34 16.9964C34 26.3659 26.3786 34 17 34ZM17 2.3138C8.9 2.3138 2.30714 8.90527 2.30714 17.0036C2.30714 25.1019 8.9 31.6933 17 31.6933C25.1 31.6933 31.6929 25.1019 31.6929 17.0036C31.6929 8.89813 25.1 2.3138 17 2.3138Z"
      fill="#17233C"
    />
    <path
      d="M24.8503 23.1312C23.036 23.1312 21.3431 22.4099 20.086 21.0959L19.9931 20.9959C19.186 20.1818 18.1074 19.7319 16.9574 19.7319C15.7931 19.7319 14.7146 20.1818 13.9074 20.9959L13.8217 21.0959C12.5646 22.4099 10.8717 23.1312 9.05744 23.1312C7.23601 23.1312 5.53601 22.4028 4.27886 21.0887C3.45744 20.2318 2.35744 19.7605 1.17172 19.7605V17.4467C2.99315 17.4467 4.68601 18.1751 5.94315 19.4891C6.76458 20.3532 7.86458 20.8245 9.05029 20.8245C10.2003 20.8245 11.2789 20.3746 12.086 19.5605L12.1646 19.4677C13.4217 18.1465 15.1217 17.4181 16.9431 17.4181C18.7717 17.4181 20.4717 18.1465 21.7289 19.4677L21.8146 19.5676C22.6217 20.3746 23.6931 20.8174 24.8431 20.8174C26.0289 20.8174 27.1289 20.346 27.9431 19.4891C29.2074 18.1679 30.9003 17.4395 32.7217 17.4395V19.7533C31.536 19.7533 30.436 20.2246 29.6217 21.0816C28.3646 22.4028 26.6717 23.1312 24.8503 23.1312Z"
      fill="#17233C"
    />
    <path
      d="M11.6141 21.6884C12.407 18.2819 14.2927 15.2969 16.9998 13.2473C19.7141 15.2897 21.5999 18.2819 22.3856 21.6884H24.7427C23.8498 17.2393 21.3356 13.3401 17.6356 10.8692L16.9927 10.4407L16.3498 10.8692C12.6498 13.3401 10.1356 17.2393 9.24271 21.6884H11.6141Z"
      fill="#17233C"
    />
    <path
      d="M10.7214 21.6883C10.6285 21.0313 10.5714 20.3672 10.5714 19.6959C10.5714 14.7969 12.9642 10.3193 16.9999 7.56986C21.0428 10.3193 23.4285 14.7969 23.4285 19.6959C23.4285 20.3672 23.3642 21.0313 23.2785 21.6883H25.6142C25.6928 21.0313 25.7428 20.3672 25.7428 19.6959C25.7428 13.7471 22.6999 8.33398 17.6071 5.20607L16.9999 4.84186L16.3928 5.21321C11.2999 8.33398 8.25708 13.7543 8.25708 19.703C8.25708 20.3743 8.30708 21.0385 8.38565 21.6955H10.7214V21.6883Z"
      fill="#17233C"
    />
  </svg>
);

export default SmallLogo;

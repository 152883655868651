const YT = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.0295C18.0914 23.0295 23.0295 18.0914 23.0295 12C23.0295 5.90858 18.0914 0.970508 12 0.970508C5.90858 0.970508 0.970508 5.90858 0.970508 12C0.970508 18.0914 5.90858 23.0295 12 23.0295ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#17233C"
    />
    <path
      d="M18.0988 9.38766C17.9593 8.40502 17.4012 7.85911 16.5293 7.78632C15.6922 7.74993 14.8203 7.67714 13.9832 7.67714C12.065 7.64075 10.1467 7.64075 8.22842 7.78632C7.6355 7.82272 7.11234 8.04108 6.83332 8.65978C6.69381 8.98733 6.58917 9.31487 6.5543 9.67881C6.41478 10.9526 6.41478 12.2264 6.48454 13.5002C6.51942 13.9733 6.5543 14.4464 6.65893 14.9196C6.79844 15.5383 7.14722 15.975 7.77501 16.157C7.9494 16.1933 8.15867 16.2297 8.33306 16.2661C9.65841 16.3753 11.0186 16.3753 12.3789 16.3753C12.7974 16.3753 13.181 16.3753 13.5996 16.3753C14.0181 16.3753 14.4018 16.3389 14.8203 16.3389C15.3783 16.3389 15.9713 16.3025 16.5293 16.2661C17.471 16.1934 17.9244 15.7566 18.1337 14.8104C18.2732 14.2281 18.2732 13.6822 18.3081 13.0999C18.2383 11.8261 18.2732 10.6251 18.0988 9.38766ZM11.8906 13.3546C11.6464 13.5002 11.4023 13.6094 11.193 13.7549C11.1233 13.7913 11.0884 13.7913 11.0884 13.6822C11.0884 12.4448 11.0884 11.2438 11.0884 10.0064C11.0884 9.89718 11.1233 9.82439 11.2279 9.89718C12.3091 10.5159 13.4252 11.171 14.5064 11.7897C14.5064 11.7897 14.5064 11.8261 14.5413 11.8261C13.6693 12.3356 12.7974 12.8451 11.8906 13.3546Z"
      fill="#17233C"
    />
  </svg>
);

export default YT;

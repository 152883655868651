const FB = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.0295C18.0914 23.0295 23.0295 18.0914 23.0295 12C23.0295 5.90858 18.0914 0.970508 12 0.970508C5.90858 0.970508 0.970508 5.90858 0.970508 12C0.970508 18.0914 5.90858 23.0295 12 23.0295ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#17233C"
    />
    <path
      d="M9.97304 8.53697V9.8435H8.81116V12.0646H9.84394V18.336H12.6841V12.0646H14.4915L14.7497 9.97416H12.8132V8.79828C12.8132 8.27566 12.8132 7.8837 13.7169 7.8837H14.8788V5.6626H12.8132C10.6185 5.6626 9.97304 6.70782 9.97304 8.53697Z"
      fill="#17233C"
    />
  </svg>
);

export default FB;

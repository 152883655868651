const Twitter = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3868 8.66842C17.9032 8.40711 18.1614 7.8845 18.4196 7.36189C18.0323 7.62319 17.5159 7.75385 16.9995 7.8845H16.8704C16.2249 7.36189 15.5794 7.10058 14.8048 7.23123C14.1593 7.36189 13.5139 7.75385 13.1266 8.27646C12.8684 8.79907 12.7393 9.32169 12.7393 9.8443C12.7393 9.97495 12.7393 9.97495 12.7393 10.1056C10.8028 9.97495 9.12457 9.06038 7.83361 7.62319C7.57541 8.14581 7.44631 8.79907 7.57541 9.32169C7.70451 9.97495 8.0918 10.3669 8.60818 10.7589C8.22089 10.7589 7.8336 10.6282 7.57541 10.4976C7.57541 11.0202 7.70451 11.4121 7.9627 11.8041C8.34999 12.3267 8.86638 12.7187 9.51186 12.8493C9.12457 12.98 8.86638 12.98 8.47909 12.8493C8.73728 13.6332 9.51187 14.5478 10.6737 14.5478C10.2864 14.8091 9.89915 15.0704 9.51186 15.2011C8.73728 15.4624 7.9627 15.593 7.05902 15.593C8.22089 16.377 9.51186 16.6383 10.9319 16.6383C11.3192 16.6383 11.8356 16.6383 12.2229 16.5076C13.5139 16.2463 14.5466 15.593 15.4503 14.6785C15.9667 14.1559 16.354 13.5026 16.7413 12.7187C16.9995 12.0654 17.2577 11.2815 17.3868 10.6282C17.3868 10.2363 17.3868 9.97495 17.3868 9.58299C17.3868 9.58299 17.3868 9.58299 17.3868 9.45234C17.7741 9.19103 18.0323 8.92973 18.2905 8.66842C18.4195 8.53777 18.4195 8.40711 18.5486 8.27646C18.2905 8.53777 17.9032 8.66842 17.3868 8.66842Z"
      fill="#17233C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.0295C18.0914 23.0295 23.0295 18.0914 23.0295 12C23.0295 5.90858 18.0914 0.970508 12 0.970508C5.90858 0.970508 0.970508 5.90858 0.970508 12C0.970508 18.0914 5.90858 23.0295 12 23.0295ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#17233C"
    />
  </svg>
);

export default Twitter;

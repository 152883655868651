const TikTok = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9501 8.57528C15.8636 8.51869 15.7772 8.49039 15.7195 8.43379C15.489 8.29229 15.2872 8.1225 15.0855 7.9244C14.6244 7.41502 14.4515 6.87733 14.3938 6.50945C14.3362 6.19816 14.365 6.00006 14.365 6.00006H12.2611V13.9804C12.2611 14.2634 12.2611 14.5181 12.1747 14.8011C12.0306 15.2256 11.6847 15.6218 11.2524 15.7916C10.676 16.018 10.2149 16.018 9.69613 15.7633C9.63849 15.735 9.55203 15.6784 9.46557 15.6218C8.83152 15.1973 8.71624 14.5464 8.71624 14.0936C8.8027 13.471 9.03327 13.1314 9.52321 12.7636C10.1861 12.2825 11.0219 12.5655 11.0219 12.5655V10.9524V10.4996C10.6184 10.443 10.2149 10.443 9.84023 10.5279C9.58085 10.5845 9.32147 10.6411 9.09091 10.7543C8.68742 10.8958 8.34158 11.1222 8.02456 11.3769C7.6499 11.6882 7.3617 12.0561 7.13113 12.4523C7.04467 12.5938 6.69883 13.2163 6.67001 14.1785C6.64119 14.7445 6.81411 15.3105 6.90057 15.5369C6.90057 15.5369 6.90057 15.5369 6.90057 15.5652C6.87175 15.5935 6.87175 15.6218 6.87175 15.6218L6.84293 15.5369C6.90057 15.7067 7.10231 16.1595 7.41934 16.5273C7.67872 16.8386 7.99574 17.1216 8.34158 17.3763C9.35029 18.0555 10.5031 17.9989 10.5031 17.9989C10.7048 17.9989 11.3677 17.9989 12.117 17.6593C12.9528 17.2631 13.4139 16.6971 13.4139 16.6971C13.4716 16.6122 13.8751 16.1878 14.1344 15.5369C14.3362 15.0558 14.3938 14.4615 14.3938 14.2351V9.99024C14.4226 10.0185 14.7685 10.2166 14.7685 10.2166C14.7685 10.2166 15.2584 10.5279 16.0366 10.726C16.5842 10.8675 17.3335 10.8958 17.3335 10.8958V9.28276V8.82998C17.0165 8.91487 16.4977 8.82998 15.9501 8.57528Z"
      fill="#17233C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.0295C18.0914 23.0295 23.0295 18.0914 23.0295 12C23.0295 5.90858 18.0914 0.970508 12 0.970508C5.90858 0.970508 0.970508 5.90858 0.970508 12C0.970508 18.0914 5.90858 23.0295 12 23.0295ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#17233C"
    />
  </svg>
);

export default TikTok;

const Pin = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 23.0295C18.0914 23.0295 23.0295 18.0914 23.0295 12C23.0295 5.90858 18.0914 0.970508 12 0.970508C5.90858 0.970508 0.970508 5.90858 0.970508 12C0.970508 18.0914 5.90858 23.0295 12 23.0295ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
      fill="#17233C"
    />
    <path
      d="M12.1139 5.38922C7.46242 5.38922 5.11682 8.72394 5.11682 11.5054C5.11682 13.1892 5.75432 14.6871 7.12142 15.2448C7.34567 15.3372 7.54664 15.2482 7.6117 15C7.65689 14.8288 7.76405 14.3953 7.81164 14.2142C7.87703 13.9688 7.8517 13.8832 7.67058 13.6685C7.27651 13.2039 7.02418 12.602 7.02418 11.7488C7.02418 9.27481 8.87539 7.06 11.8445 7.06C14.4735 7.06 15.918 8.66642 15.918 10.8114C15.918 13.6346 14.6687 16.0172 12.8144 16.0172C11.79 16.0172 11.0238 15.1705 11.2689 14.1314C11.563 12.891 12.1331 11.553 12.1331 10.657C12.1331 9.85582 11.7027 9.18751 10.8129 9.18751C9.76591 9.18751 8.9247 10.2708 8.9247 11.7214C8.9247 12.6455 9.23694 13.2707 9.23694 13.2707C9.23694 13.2707 8.16565 17.8105 7.97769 18.6055C7.60382 20.189 7.92154 22.1296 7.94859 22.3254C7.96434 22.4418 8.11361 22.4699 8.1814 22.3822C8.27761 22.2559 9.52522 20.7166 9.94873 19.178C10.0689 18.7428 10.6372 16.4872 10.6372 16.4872C10.9776 17.1364 11.9718 17.7068 13.0291 17.7068C16.1758 17.7068 18.3112 14.838 18.3112 10.998C18.3115 8.09363 15.8519 5.38922 12.1139 5.38922Z"
      fill="#17233C"
    />
  </svg>
);

export default Pin;

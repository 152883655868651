const ChevronIcon = ({
  height = '16px',
  width = '16px',
  fill = '#0e7873',
  transform = 'rotate(-90deg)',
  style = {},
  className = '',
}) => (
  <svg fill={fill} viewBox="0 0 1008 1008" className={className} style={{ transform, height, width, ...style }}>
    <path d="M875.4 349.4c0 6.6-4 13.2-7.9 17.2L521.2 712.8c-4 4-11.9 7.9-17.2 7.9-6.6 0-13.2-4-17.2-7.9L140.5 366.6c-4-4-7.9-10.6-7.9-17.2 0-5.3 4-13.2 7.9-17.2l37-37c4-4 10.6-7.9 17.2-7.9 5.3 0 13.2 4 17.2 7.9L504 587.3l292.1-292.1c4-4 11.9-7.9 17.2-7.9 6.6 0 13.2 4 17.2 7.9l37 37c3.9 4 7.9 11.9 7.9 17.2z" />
  </svg>
);

export default ChevronIcon;
